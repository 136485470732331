#account-avatar {
  margin-top: -0.8rem;
}

.navbar {
  min-height: 4rem;
  font-size: 0.9rem;
  /*border-bottom: 1px solid #50596c;*/
}

.navbar .navbar-brand {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #50596c;
  max-width: 5rem;
}

.navbar .btn {
  font-size: 0.9rem;
}

.navbar .btn-link {
  color: #50596c;
}

.navbar .date {
  font-size: 80%;
}

.navbar .dropdown-toggle .icon {
  vertical-align: inherit;
  top: -10%;
}

.navbar .account-section .dropdown-toggle .icon {
  vertical-align: inherit;
  top: -40%;
}

#sidebar .brand {
  max-width: 4rem;
  margin-bottom: 1rem;
}

.nav .nav-item {
  font-size: 0.8rem;
}

.nav .nav-item a.active {
  color: #5764c6;
}

.docs-sidebar .docs-nav .nav-item {
  word-break: break-all;
}

/*
@media screen and (min-width: 601px) {
  #sidebar {
    display: none;
  }
}
*/

@media screen and (max-width: 800px) {
  .navbar .navbar-content {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .navbar .navbar-content .xtra {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .navbar .navbar-content .xtra {
    display: block;
  }
}

.docs-sidebar {
  background: #fff;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 1rem 1.6rem;
  position: fixed;
  top: 0;
  width: 18rem;
  z-index: 400;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
}

.docs-sidebar.show {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
}

.docs-nav-clear {
  background: rgba(0, 0, 0, 0.15);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 300;
}
