.workout-breakdown {
  font-size: 90%;
}

.workout-breakdown table td {
  width: 60%;
}

.publik-workout-notes {
  font-size: 80%;
}
