/**
 * Utility
 */

.section-content {
  margin-top: 2rem;
}

.section-content h4 {
  margin-bottom: 1rem;
}

.section-content h3 {
  margin-bottom: 2rem;
}

.margin-auto {
  margin: auto;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.text-center {
  text-align: center;
}

.float-actions {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.bg-gray {
  background: #f8f9fa;
  color: #667189;
}

.width-100 {
  width: 100%;
}

.form-group-action {
  margin-top: 2rem;
}

.hand {
  cursor: pointer;
}

/**
 * Animations
 */

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.fade-in {
  animation: fadeIn 500ms;
  -moz-animation: fadeIn 500ms;
  -webkit-animation: fadeIn 500ms;
  -o-animation: fadeIn 500ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
