.global-notification {
  animation: slide-down 0.5s ease 1;
}

.user-notification {
  padding: 0.8rem 0.8rem;
  margin-top: 1rem;
}

.notification-timestamp {
  font-size: 80%;
}
