.workout-month-row {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.workout-panel {
  font-size: 0.7rem;
  background: #f8f9fa;
}

/*
.workout-panel .menu {
  font-weight: normal;
}
*/

.workout-panel-notes {
  word-break: break-all;
}
