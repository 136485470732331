.home {
  font-size: 0.8rem;
}

.home section h4,
h3 {
  margin-bottom: 2.5rem;
}

.home .welcome .hero {
  background-image: url("./scott-webb-22537-o50.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 38rem;
}

.home .welcome .hero .content {
  padding-top: 6rem;
  text-align: center;
  color: white;
}

.home .welcome .hero .content .title {
  font-size: 3.8rem;
  margin-left: 1px;
  margin-right: 1px;
}

.home .welcome .hero .content .subtitle {
  font-size: 1.2rem;
  margin-left: 1px;
  margin-right: 1px;
}

.home .welcome .hero .content .action {
  margin-top: 4rem;
  margin-bottom: 0.6rem;
  font-weight: 100;
  font-size: 0.9rem;
}

@media only screen and (min-width: 1280px) {
  .home .welcome .hero {
    height: 32rem;
  }
}

@media only screen and (min-width: 640px) {
  .home .welcome .hero {
    height: 32rem;
  }
}

.home .btn-welcome-log-workout {
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
  height: 3rem;
  padding: 1rem 1rem;
  background: #e85600;
  color: #fff;
  border: none;
}

.home .btn-welcome-log-workout:hover {
  background: #e85600;
  border: none;
}

.home .highlights {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.home .highlights .highlight {
  padding: 2rem;
}

.home .highlights .highlight i {
  margin-bottom: 1rem;
}

.home .features {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: rgba(69, 77, 93, 0.9);
  color: white;
  /*
  background-size: 40px 40px;
  background-image: linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px);
  */
}

.home .features .feature-list {
  /*
  max-width: 500px;
  margin: 0 auto;
  */
  padding-left: 1rem;
  padding-right: 1rem;
}

.home .features .feature-list .feature-list-item {
  padding-top: 1rem;
}

.home .analytics {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.home .last-call {
  padding-top: 3rem;
}
