.entry {
  font-family: "Merriweather", serif;
}

.entry-divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.entry .link {
  font-size: 1rem;
}

.entry .author {
  margin-top: 0.4rem;
}

.entry .date {
  color: #bbc1cd;
}

p .highlight {
  font-style: italic;
  text-decoration: underline;
}

img.bordered {
  border: 1px solid #727e96;
}

figure.blog {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.7rem;
  font-family: "Merriweather", serif;
}
