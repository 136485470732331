.btn-exercise-action {
  margin-left: 10px;
}

.exercise-entry {
  /*
  border-top: 1px solid #50596c;
  padding-top: 1rem;
  */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.exercise-entry .exercise-operations {
  margin-top: 0.4rem;
}

.exercise-entry .exercise-readonly-row {
  margin-top: 0.4rem;
}

.exercise-entry .exercise-notes-readonly {
  margin-top: 0.2rem;
  font-size: 75%;
}

.secondary-operation {
  margin-top: 1rem;
}

.secondary-operation .quote {
  margin-top: 2rem;
  font-style: italic;
}
