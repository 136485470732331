.workout-details {
  margin-left: 1rem;
  font-weight: 300;
  font-size: 0.7rem;
}

.workout-details .workout-exercise.row {
  border-bottom: 1px solid #727e96;
  padding-top: 1rem;
}

.workout-details .exercise-notes {
  font-size: 90%;
}

.workout-details .workout-exercise.row-super-set {
  padding-top: 0.2rem;
}

.workout-details .row {
  padding-top: 0.2rem;
}
