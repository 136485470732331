/**
 * Sticky footers
 */

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  padding-bottom: 18rem;
  position: relative;
}

@media screen and (min-width: 601px) {
  body {
    padding-bottom: 14rem;
  }
}

/**
 * Spectre overrides
 */

body {
  background: white;
  font-family: Lato, "Open Sans", sans-serif;
  color: #50596c;
  /* need to figure out a solution for global variables */
}

p,
ul.text {
  font-weight: 300;
  font-family: "Merriweather", serif;
  margin-top: 1.5rem;
  line-height: 1.8;
  font-size: 0.8rem;
}

p.no-text {
  font-family: Lato, "Open Sans", sans-serif;
  margin-top: 0;
  line-height: 1.5;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.accordion .accordion-item .accordion-body-show {
  margin-bottom: 0.8rem;
  overflow: hidden;
  /*padding: 0.2rem 0.8rem;*/
  /* max-height is required here for the ease transition */
  max-height: 5000rem;
  transition: max-height 8s ease;
}

.menu {
  font-size: 0.7rem;
}

.menu-item a i {
  margin-right: 0.6rem;
}

.dropdown .menu.active {
  display: block !important;
}

.dropdown .menu {
  display: none !important;
}

.dropdown.active .menu,
.dropdown .dropdown-toggle:focus + .menu,
.dropdown .menu:hover {
  display: initial;
}

.form-autocomplete .form-autocomplete-input .form-input {
  height: 1.6rem;
}

/* TODO: check if the following are required */
.tile .tile-title {
  font-size: 0.9rem;
}

.tile .tile-subtitle {
  margin-top: 0.2rem;
}

/**
 * Chartist overrides
 */

.ct-label {
  font-size: 0.6rem;
}
