footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 0.8rem;
}

footer a {
  color: #727e96;
}

footer .r {
  margin-top: 0.4rem;
}

footer a.social-icon {
  font-size: 1.4rem;
  margin-right: 0.6rem;
}

#copyright {
  padding: 1rem 1rem;
  color: #acb3c2;
}
