.noty_theme__spectre.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  font-size: 1em;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
}

.noty_theme__spectre.noty_bar .noty_body {
  padding: 1em 1.5em;
  line-height: 1.4285em;
}

.noty_theme__spectre.noty_bar .noty_buttons {
  padding: 10px;
}

.noty_theme__spectre.noty_type__alert,
.noty_theme__spectre.noty_type__notification {
  background-color: #303742;
  color: #fff;
}

.noty_theme__spectre.noty_type__warning {
  background-color: #ffb700;
  color: #fff;
  box-shadow: 0 0 0 1px #e6a400 inset, 0 0 0 0 transparent;
}

.noty_theme__spectre.noty_type__error {
  background-color: #e85600;
  color: #fff;
  box-shadow: 0 0 0 1px #cf4c00 inset, 0 0 0 0 transparent;
}

.noty_theme__spectre.noty_type__info,
.noty_theme__spectre.noty_type__information {
  background-color: #5755d9;
  color: #fff;
  box-shadow: 0 0 0 1px #4d4bbf inset, 0 0 0 0 transparent;
}

.noty_theme__spectre.noty_type__success {
  background-color: #32b643;
  color: #fff;
  box-shadow: 0 0 0 1px #2a9c39 inset, 0 0 0 0 transparent;
}
